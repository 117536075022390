import { func } from 'prop-types'

import { ActiveFiltersPropTypes } from '../../shared/prop-types'

const ActiveFilters = ({
  onFilterReset,
  filters: activeFilters = {},
  onFilterDelete,
}) => {
  if (Object.keys(activeFilters).length === 0) {
    return null
  }

  // ActiveFilters component expects an array. We must therefore massage
  // existing filters to get the expected format.
  const flatFilters = Object.entries(activeFilters).flatMap(([id, filters]) =>
    filters.map((filter) => ({ id, ...filter }))
  )

  return (
    <div className='c-active-filter'>
      <div role='heading' aria-level='2' className='c-active-filter__title'>
        Ma sélection
      </div>
      <ul className='c-active-filter__list'>
        {flatFilters.map((filter) => (
          <li
            className='c-active-filter__item'
            key={`${filter.key}-${filter.label}`}
          >
            <span className='u-visually-hidden'>{filter.label}</span>
            <a
              aria-label={`Supprimer ${filter.label}`}
              className='c-tag c-tag--remove'
              href='#'
              onClick={(e) => handleRemoval(e, filter)}
            >
              {filter.label}
            </a>
          </li>
        ))}

        <li className='c-active-filter__item'>
          <a
            className='c-tag c-tag--remove-all'
            href='#'
            onClick={onFilterReset}
          >
            Tout supprimer
          </a>
        </li>
      </ul>
    </div>
  )

  function handleRemoval(event, filter) {
    event.preventDefault()
    onFilterDelete(filter)
  }
}

ActiveFilters.propTypes = {
  filters: ActiveFiltersPropTypes,
  onFilterDelete: func.isRequired,
  onFilterReset: func.isRequired,
}

export default ActiveFilters
